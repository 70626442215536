<template>
  <div>
    <barns-list-add-new
      :is-add-new-barn-sidebar-active.sync="isAddNewBarnSidebarActive"
      @refetch-data="refetchData"
    />
    <barns-list-edit
      :key="JSON.stringify(selectedBarn)"
      :is-edit-barn-sidebar-active.sync="isEditBarnSidebarActive"
      :barn.sync="selectedBarn"
      @refetch-data="refetchData"
    />
    <barn-list-add-padok
      :key="JSON.stringify(selectedBarn) + 'padok'"
      :is-add-new-padok-sidebar-active.sync="isAddNewPadokSidebarActive"
      :selected-barn.sync="selectedBarn"
      @refetch-data="getAnimalPadok"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              transition=""
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>adet</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewBarnSidebarActive = !isAddNewBarnSidebarActive"
              >
                <span class="text-nowrap">{{ $t("newBarn") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refBarnListTable"
        class="position-relative"
        :items="fetchBarns"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(pictureurl)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="
                  'https://api.herdoncloud.com/Barn_Pictures/' +
                  data.item.pictureurl
                "
                :text="avatarText(data.item.barnname)"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.barnname }}
            </span>
            <small class="text-muted">@{{ data.item.responsible }}</small>
          </b-media>
        </template>
        <template #cell(padok)="data">
          <div class="d-flex">
            <b-link
              class="d-flex align-items-center ml-1"
              @click="editBarnPadok(data)"
            >
              <feather-icon icon="EyeIcon" />
              <small class="align-middle ml-50 text-dark">
                {{ $t("show") }}</small
              >
            </b-link>
          </div>
        </template>
        <template #cell(delete)="data">
          <b-link @click="deleteBarn(data.item.id)">
            <div class="d-flex">
              <feather-icon icon="TrashIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBarns"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="padok"
      ref="padokModal"
      v-model="isEditPadokSidebarActive"
      centered
      :title="$t('padokEdit')"
      size="md"
      :ok-title="$t('update')"
      ok-only
      @ok="updatePadok"
    >
      <div class="d-flex flex-column align-items-end">
        <b-button
          variant="success"
          class="d-flex align-items-center mb-2"
          @click="addBarnPadok(selectedBarn)"
        >
          <feather-icon icon="PlusIcon" />
          <span class="align-middle ml-50"> {{ $t("add") }}</span>
        </b-button>
        <b-table
          :responsive="true"
          sticky-header="true"
          :no-border-collapse="true"
          :items="animalPadoks"
          :fields="[
            { key: 'padokname', label: $t('padokName') },
            { key: 'delete', label: $t('delete') },
          ]"
        >
          <template #cell(padokname)="{ item, index }">
            <b-form-input
              id="padokname"
              :value="item.padokname"
              autofocus
              trim
              placeholder=""
              @input="animalPadoks[index].padokname = $event"
            />
          </template>
          <template #cell(delete)="data">
            <b-link @click="deleteAnimalPadok(data.item.id)">
              <div class="d-flex">
                <feather-icon icon="TrashIcon" />
                <small class="align-middle ml-50 text-dark">{{
                  $t("delete")
                }}</small>
              </div>
            </b-link>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
  BMedia,
  BAvatar,
  BModal,
} from "bootstrap-vue";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useBarnList from "../../useBarnList";
import BarnsListAddNew from "./BarnListAddNew.vue";
import BarnsListEdit from "./BarnListEdit.vue";
import BarnListAddPadok from "./BarnListAddPadok.vue";
import vSelect from "vue-select";
import { getUserData } from "@/auth/utils";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BarnsListAddNew,
    BarnsListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BMedia,
    BAvatar,
    vSelect,
    BarnListAddPadok,
    BModal,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },
  setup({ emit }) {
    const vm = getCurrentInstance();

    const isAddNewBarnSidebarActive = ref(false);
    const isEditBarnSidebarActive = ref(false);
    const isAddNewPadokSidebarActive = ref(false);
    const isEditPadokSidebarActive = ref(false);
    const selectedBarn = ref({});
    const animalPadoks = ref([]);
    let ANIMAL_PADOKS = [];
    const userData = getUserData();

    const openEditSideBar = ({ item }) => {
      selectedBarn.value = item;
      isEditBarnSidebarActive.value = !isEditBarnSidebarActive.value;
    };

    const addBarnPadok = (item) => {
      selectedBarn.value = item;
      isAddNewPadokSidebarActive.value = true;
    };

    const editBarnPadok = ({ item }) => {
      selectedBarn.value = item;
      isEditPadokSidebarActive.value = !isEditPadokSidebarActive.value;
      getAnimalPadok();
    };

    const getAnimalPadok = async () => {
      var data = await store.dispatch("definitionsModule/fetchPadoks", {
        barnid: selectedBarn.value.id,
        plantid: store.state.app.selectedPlantId,
      });
      animalPadoks.value = [];
      data.map((x) =>
        animalPadoks.value.push({
          label: x.padokname,
          value: x.id,
          ...x,
        })
      );
      ANIMAL_PADOKS = JSON.parse(JSON.stringify([...animalPadoks.value]));

      return data;
    };

    const updatePadok = async () => {
      var changedPadok = animalPadoks.value.filter(
        (cp) =>
          ANIMAL_PADOKS.findIndex(
            (ap) => ap.padokname == cp.padokname && ap.id == cp.id
          ) == -1
      );

      changedPadok.forEach((changedItem) =>
        store
          .dispatch("definitionsModule/updatePadok", changedItem)
          .then((res) => {
            if (res.statusdata == "-2") {
              vm.proxy.$toast({
                component: ToastificationContent,
                props: {
                  title: vm.proxy.$t("animalPadokAlreadyAdded"),
                  icon: "AlertCircleIcon",
                  variant: "alert",
                },
              });
            } else if (res.statusdata == "0") {
              vm.proxy.$toast({
                component: ToastificationContent,
                props: {
                  title: vm.proxy.$t("animalPadokUpdated"),
                  icon: "AlertCircleIcon",
                  variant: "success",
                },
              });
            } else {
              vm.proxy.$toast({
                component: ToastificationContent,
                props: {
                  title: vm.proxy.$t("notUpdated"),
                  icon: "AlertCircleIcon",
                  variant: "success",
                },
              });
            }
          })
      );
      await getAnimalPadok();
    };

    const deleteAnimalPadok = async (id) => {
      store
        .dispatch("definitionsModule/deletePadok", id)
        .then((response) => {
          if (response)
            animalPadoks.value = animalPadoks.value.filter((x) => x.id != id);
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("successful"),
              icon: "CheckIcon",
              variant: "success",
              text: vm.proxy.$t("deleted", { type: vm.proxy.$t("padok") }),
            },
          });
        })
        .catch((e) => {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("unsuccessful"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: vm.proxy.$t("notDeleted", { type: vm.proxy.$t("padok") }),
            },
          });
        });
    };

    const {
      fetchBarns,
      tableColumns,
      perPage,
      currentPage,
      totalBarns,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBarnListTable,
      refetchData,

      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteBarn,
    } = useBarnList();

    return {
      // Sidebar
      isAddNewBarnSidebarActive,
      isEditBarnSidebarActive,
      isAddNewPadokSidebarActive,
      isEditPadokSidebarActive,

      addBarnPadok,
      editBarnPadok,
      openEditSideBar,
      animalPadoks,

      selectedBarn,
      updatePadok,
      getAnimalPadok,
      fetchBarns,
      tableColumns,
      perPage,
      currentPage,
      totalBarns,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBarnListTable,
      refetchData,
      perPageOptions,
      // Filter
      avatarText,
      // UI

      // Extra Filters
      typeFilter,
      genderFilter,
      deleteBarn,
      deleteAnimalPadok,
      updatePadok,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.form-custom-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.form-custom-control + .form-custom-control {
  margin-top: 1em;
}

.form-custom-control--disabled {
  color: var(--form-custom-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.55em;
  height: 1.55em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0) translateX(-1em);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-custom-control-color);
  /* Windows High Contrast Mode */
  background-color: #fe671d;
}
input[type="checkbox"]:checked::before {
  transform: scale(1) translateX(0);
}
input[type="checkbox"]:disabled {
  --form-custom-control-color: var(--form-custom-control-disabled);
  color: var(--form-custom-control-disabled);
  cursor: not-allowed;
}
.checkbox-span {
  font-size: 14px;
  user-select: none;
}
.box {
  padding: 2px;
  height: calc(100vh - 20vh);
  overflow-y: auto;
}
.b-table-sticky-header {
  max-height: calc(100vh - 30vh);
  min-height: calc(386px);
}
.table-responsive > .table.b-table > tbody > tr > .b-table-sticky-column {
  background-color: white !important;
}
</style>
