<template>
  <div>
    <b-modal
      id="add-new-milking-sidebar"
      :visible="isAddNewPadokSidebarActive"
      bg-variant="white"
      centered
      size="sm"
      shadow
      backdrop
      no-header
      right
      :title="$t('addAnimalPadok')"
      ok-only
      :ok-title="$t('ok')"
      @ok="onSubmit"
      @change="(val) => changeModalShow(val)"
    >
      <template #default="{}">
        <!-- BODY -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <!-- Form -->

          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
            <!-- <b-form-group label="Type" label-for="type">
            <v-select
              transition=""
              v-model="addType"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              class="w-100"
              :reduce="(val) => val.value"
            />
          </b-form-group> -->

            <!-- padokname -->

            <validation-provider
              #default="validationContext"
              name="padokname"
              rules="required"
            >
              <b-form-group :label="$t('padokName')" label-for="padokname">
                <b-form-input
                  id="padokname"
                  v-model="blankPadok.padokname"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPadokSidebarActive",
    event: "update:is-add-new-padok-sidebar-active",
  },
  props: {
    isAddNewPadokSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedBarn: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);
    const blankPadokData = {
      id: 0,
      companyid: parseInt(getUserData().companyid),
      barnid: props.selectedBarn.id,
      plantid: parseInt(store.state.app.selectedPlantId),
      padokname: "",
    };

    const blankPadok = ref(JSON.parse(JSON.stringify(blankPadokData)));
    const resetblankPadok = () => {
      blankPadok.value = JSON.parse(
        JSON.stringify({
          id: 0,
          companyid: parseInt(getUserData().companyid),
          barnid: props.selectedBarn.id,
          plantid: parseInt(store.state.app.selectedPlantId),
          padokname: "",
        })
      );
      emit("update:is-add-new-padok-sidebar-active", false);
    };

    const onSubmit = () => {
      store
        .dispatch("definitionsModule/addPadok", blankPadok.value)
        .then((res) => {
          if (res.statusdata == "-2") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("padokAlreadyAdded"),
                icon: "AlertCircleIcon",
                variant: "alert",
              },
            });
          } else if (res.statusdata == "0") {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("padokAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          } else {
            vm.proxy.$toast({
              component: ToastificationContent,
              props: {
                title: vm.proxy.$t("PadokNotAdded"),
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
          }
        });
      resetblankPadok();
      emit("refetch-data");
      emit("update:is-add-new-padok-sidebar-active", false);
    };

    const changeModalShow = (val) => {
      emit("update:is-add-new-padok-sidebar-active", val);
      resetblankPadok();
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankPadok);
    return {
      blankPadok,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      changeModalShow,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-milking-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
