<template>
  <b-modal
    id="add-new-barn-sidebar"
    centered
    :visible="isAddNewBarnSidebarActive"
    bg-variant="white"
    style="z-index: 99999"
    shadow
    size="sm"
    backdrop
    no-header
    right
    :ok-title="$t('ok')"
    ok-only
    :title="$t('barnAdd')"
    @ok="onSubmit(blankBarnData)"
    @hidden="formValidation(resetblankbarn).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankbarn
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- barnName -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="barnName"
          class="w-100"
        >
          <b-form-group :label="$t('barnName')" label-for="barnName">
            <b-form-input
              id="barnName"
              v-model="blankBarnData.barnname"
              autofocus
              :state="
                formValidation(resetblankbarn).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewBarnSidebarActive",
    event: "update:is-add-new-barn-sidebar-active",
  },
  props: {
    isAddNewBarnSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankBarnData: {
        id: 0,
        barnname: "",
        companyid: getUserData().companyid,
        plantid: this.$store.state.app.selectedPlantId,
      },
      formValidation: formValidation,
    };
  },
  async mounted() {},
  methods: {
    async changed(val) {
      this.$emit("update:is-add-new-barn-sidebar-active", val);
      this.resetblankbarn();
    },
    onSubmit(blankBarnData) {
      store.dispatch("definitionsModule/addBarn", blankBarnData).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-add-new-barn-sidebar-active", false);
      });
    },
    async resetblankbarn() {
      this.blankBarnData = {
        id: 0,
        barnname: "",
        companyid: getUserData().companyid,
        plantid: this.$store.state.app.selectedPlantId,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-barn-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
