<template>
  <div><barn-list /></div>
</template>

<script>
import BarnList from "./components/BarnList/BarnList.vue";
export default {
  components: { BarnList },
};
</script>

<style></style>
