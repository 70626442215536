<template>
  <b-modal
    id="edit-barn-sidebar"
    centered
    :visible="isEditBarnSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="sm"
    backdrop
    no-header
    right
    :title="$t('updateBarn')"
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankBarnData)"
    @hidden="formValidation(resetblankbarn).resetForm"
    @change="(val) => changed(val)"
  >
    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblankbarn
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- BarnTopic -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="BarnTopic"
          class="w-100"
        >
          <b-form-group :label="$t('barnName')" label-for="BarnName">
            <b-form-input
              id="BarnName"
              v-model="blankBarnData.barnname"
              autofocus
              :state="
                formValidation(resetblankbarn).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditBarnSidebarActive",
    event: "update:is-edit-barn-sidebar-active",
  },
  props: {
    isEditBarnSidebarActive: {
      type: Boolean,
      required: true,
    },
    barn: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankBarnData: { ...this.barn },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.resetblankbarn();

      this.$emit("update:is-edit-barn-sidebar-active", val);
    },
    onSubmit() {
      this.blankBarnData.imgstr = this.imgStr;
      store
        .dispatch("definitionsModule/updateBarn", this.blankBarnData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-barn-sidebar-active", false);
        });
    },
    async resetblankbarn() {
      this.blankBarnData = { ...this.barn };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-barn-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
